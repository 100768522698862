<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">增加/修改 类型</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>名称</b></label>
            <el-input type="text" v-model="model.NAME" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>图标</b></label>
            <!-- <el-input type="text" v-model="model.ICON" /> -->
            <div v-if="model.ICON_KV">
              <el-image :src="model.ICON_KV.Value" style="width: 100px; height: 100px" fit="cover"/>
              <el-link type="danger" @click="delIcon">删除</el-link>
            </div>
            <el-upload v-else accept="image/*" action="" :limit="1" list-type="picture-card" :auto-upload="false"
              :on-change="onChange" :file-list="fileList" :on-remove="() => { model.PIC_B64 = '' }">
              <i class="el-icon-plus"></i>
            </el-upload>

          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        sta: {
          show: false,
          loading: false
        },
        model: {},
        fileList: []
      }
    },
    methods: {
      init(model) {
        console.log(model)
        this.sta = { show: true, loading: false }
        if (model == undefined || model == null) {
          this.model = {};
        } else {
          this.model = JSON.parse((JSON.stringify(model)));
        }
        this.fileList = [];
      },
      submit() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/INS/TypeApi/SaveType",
          data: self.model,
          finally() { self.sta.loading = false; },
          completed: function () {
            self.sta.show = false;
            self.$emit("on-saved");
          }
        })
      },
      onChange(file) {
        let self = this;
        this.whale.image.compress({
          file: file.raw,
          callback(cf) {
            self.whale.image.toB64({
              file: cf,
              callback(b64) {
                self.model.PIC_B64 = b64;
              }
            })
          }
        })
      },
      delIcon() {
        this.model.ICON_KV = null
      }
    }
  }
</script>