<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-files"> </i> 检查巡检 | 检查类型</h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit()">新增类型</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1"
              :page-size="grid.sea.PAGE_SIZE" @page-changed="loadPage">
              <el-table-column prop="NAME" label="类型名称" width="200" align="center" />
              <el-table-column prop="ICON" label="类型图标" width="150" align="center">
                <template slot-scope="scope">
                  <el-image v-if="scope.row.ICON_KV" :src="scope.row.ICON_KV.Value" fit="cover"
                    style="max-width: 100px; max-height: 50px" :preview-src-list="srcList" />
                </template>
              </el-table-column>
              <el-table-column prop="COUNT" label="项目总数" width="100" align="center" />
              <el-table-column prop="CREATE_DATE" label="创建日期" width="150" align="center" />
              <el-table-column label="操作" width="350">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-document" @click="toItem(scope.row)">项目</el-button>
                  <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)">修改</el-button>
                  <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <el-table-column />
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="archive_edit" @on-saved="filter" />
      <Item ref="item" />
    </div>
  </div>
</template>
<script>
  import Edit from "./edit";
  import Item from "./item";
  import Wgrid from "@/components/wgrid";
  export default {
    name: "index",
    components: { Wgrid, Edit, Item },
    data() {
      return {
        grid: {
          sea: {
            PAGE_SIZE: 12,
            PAGE_INDEX: 0,
          },
          ls: [],
          total: 0,
          loading: false,
        },
        srcList: []
      }
    },
    created() {
      this.getList();
    },
    methods: {
      filter() {
        this.grid.sea.PAGE_INDEX = 0;
        this.grid.ls = [];
        this.getList();
      },
      loadPage(pi) {
        this.grid.sea.PAGE_INDEX = pi - 1;
        this.getList();
      },
      getList() {
        let self = this;
        this.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/School/INS/TypeApi/GetTypeList",
          data: this.grid.sea,
          completed: function (its, n) {
            self.grid.ls = its;
            self.grid.total = n;
            self.grid.loading = false;
            its.forEach(x => {
              if (x.ICON_KV)
                self.srcList.push(x.ICON_KV.Value);
            });

          }
        })
      },
      rowEdit(r) {
        this.$refs.archive_edit.init(r);
      },
      rowDel(r) {
        this.whale.delConfirm(() => {
          let self = this;
          this.whale.remote.getResult({
            url: "/api/School/INS/TypeApi/DelType",
            data: { ID: r.ID },
            completed: function () {
              //self.filter();
              self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
            }
          })
        });
      },
      toItem(r) {
        this.$refs.item.init(r);
      },
    }
  }
</script>