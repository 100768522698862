<template>
    <div>
        <el-dialog :visible.sync="sta.show">
            <span slot="title">新增/修改 记录</span>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>项目名称</b></label>
                        <el-input type="text" v-model="model.NAME" />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>序号</b></label>
                        <el-input type="number" v-model="model.SORT" />
                    </div>
                </div>
            </div>
            
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sta: {
                    show: false,
                    loading: false
                },
                model: {},
            }
        },
        methods: {
            init(model) {
                this.sta = { show: true, loading: false }
                this.model = JSON.parse((JSON.stringify(model)));
            },
            submit() {
                let self = this;
                if (!self.model.NAME) {
                    self.$message.warning('请填写项目名称！')
                    return
                }
                self.sta.loading = true;
                this.whale.remote.getResult({
                    url: "/api/School/INS/TypeApi/SaveItem",
                    data: self.model,
                    finally() { self.sta.loading = false; },
                    completed: function () {
                        self.sta.show = false;
                        self.$emit("on-saved");
                    }
                })
            }
        }
    }
</script>